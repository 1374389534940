
.appContainer {
  width: 100%;
  height: 100%;
  background: #000;
}

.videoContainer {
  height: 100%;
}

.videoContainer * {
  padding: 0;
  border: 0;
  font-size: 12px;
}