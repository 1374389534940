section {
  width: 80%;
  height: 100%;
  text-align: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto;
}

aside {
  width: 250px;
  height: 100%;
  background-color: rgb(44, 44, 44);
  overflow: hidden;

  box-shadow: var(--shadow);
}

aside .list-tv {
  height: 300px;
}


.tabs li {
  border-right: 1px solid #181818;
}

.tabs li:last-child {
  border-right: 0;
}

.active {
  color: var(--yellow);
  font-weight: 600;
}

.d-flex {
  display: flex;
}

.d-none {
  display: none;
}

.flex-column {
  flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.grid-2-1 {
  display: grid;
  grid-template-columns: 75% 22%;
  justify-content: space-between;
  gap: 20px;
}

.max-content {
  width: max-content;
}

.w-100 {
  width: 100%;
}

.h-100 {
  min-height: 100%;
}

.br7 {
  border-radius: 7px;
}

.border-0 {
  border: 0;
}

.mr-2 {
  margin-right: 20px;
}

.mb-2 {
  margin-bottom: 20px;
}

.ml-2 {
  margin-left: 20px;
}

.ml-1 {
  margin-left: 10px;
}

.mt-1 {
  margin-top: 10px;
}

.mb-1 {
  margin-bottom: 10px;
}

.mr-1 {
  margin-right: 10px;
}


.py-1 {
  padding: 10px 0;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0;
}

.cp {
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
}

.center {
  text-align: center;
}

.truncate {
  width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-left {
  text-align: left;
}

.bg-blue {
  background-color: #3f51b5;
  color: var(--white);
}

.bg-yellow {
  background-color: var(--yellow);
}

.bg-light {
  background-color: #181818;
}

.bg-gray {
  background-color: #ffffff1f;
}

.bg-red {
  background-color: var(--red);
  color: var(--white);
}

.bg-inherit {
  background-color: inherit;
  color: inherit;
  border: 0;
}

.bg-white {
  background-color: var(--white);
  color: var(--black);
}

.white {
  color: var(--white) !important;
}

.yellow {
  color: var(--yellow);
}

.overflow {
  overflow: auto;
}

.blur {
  backdrop-filter: blur(2px);
}

.shadow {
  box-shadow: var(--shadow);
}

/********************************************/
.dropdown {
  position: relative;
  background-color: transparent;
  padding: 0 15px;
}

.dropdown button {
  font-size: .9rem;
  background-color: inherit;
  color: #fff;
  letter-spacing: 2px;
}

.dropdown ul {
  display: none;
  position: absolute;
  border: 1px solid #747474;
  z-index: 999999;
  border-radius: 7px;
}

.dropdown li {
  font-size: 0.9rem;
  padding: 7px 15px;
  background-color: #585858;
  border-bottom: 1px solid #747474;
  cursor: pointer;
}

.dropdown:hover ul,
.dropdown button:hover ul {
  display: block;
}

/**********************************************/

.modal {
  max-width: 90%;
  max-height: 90%;
  position: center;
  right: center;
  bottom: center;
  flex-direction: column;
  background-color: #585858;
  border-radius: 7px;
  border: 1px solid #747474;
}

.modal>div {
  min-width: 200px;
  max-width: 450px;
  padding: 10px;
  box-shadow: 0 1px 10px #34383c0f, 0 2px 4px #34383c14;
}

.modal header {
  padding: 10px;
  border-bottom: 1px solid #747474;
}

iframe {
  min-height: 350px;
  box-shadow: 0 1px 10px #34383c0f, 0 2px 4px #34383c14;
}

video {
  height: 100%;
  width: 100%;
  box-shadow: 0 1px 10px #34383c0f, 0 2px 4px #34383c14;
}



/********************************************/
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #585858;
  border-radius: 7px;
}

::-webkit-scrollbar-thumb:hover {
  background: inherit;
}

#footer {
  width: 80%;
  margin: auto;
  margin-top: 20px;
}

.url-input {
  height: 35px;
  width: 80%;
  border-radius: 5px;
}

.media-select {
  width: 9%;
  margin-left: 1%;
  border-radius: 7px;
}

#root {
  height: 100vh;
  width: 100vw;
}

body {
  width: 100vw;
  height: 100vh;
}

:root {
  --white: #fff;
  --black: #000;
  --red: #f74e4e;
  --yellow: #EED75F;
  --shadow: 0 1px 10px #34383c0f, 0 2px 4px #34383c14;
}

main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}